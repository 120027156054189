<div class="background-image-container">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div>
        <h1 class="display-3">Adelaide Research &amp; Scholarship</h1>
        <p>Adelaide Research & Scholarship (AR&amp;S) is the University of Adelaide’s digital repository. AR&amp;S provides a platform for the collection, organisation, access and preservation of the research and scholarly outputs of the University community in digital formats, as well as digital management of information in physical formats.</p>
        <p>University of Adelaide higher degree by research theses are deposited into the AR&amp;S Theses community as part of the final thesis lodgement process.</p>
        <p>AR&amp;S also serves as the home of the digital collections of <a href="https://www.adelaide.edu.au/library/collections-archives/">University Library Archives and Special Collections</a>. Items include digitized representations of physical items, such as photographs and full texts, and digital-born materials, allowing worldwide access to our heritage and research collections.</p>
        <p><b>Are you a University of Adelaide researcher who would like your publications in AR&amp;S? See our <a href="https://www.adelaide.edu.au/library/library-services/services-for-researchers/adelaide-research-scholarship">support page</a>.</b></p>
        <p><b>Contact us.</b> Please email <a href="mailto:digital-library@adelaide.edu.au">Library Discovery</a>.</p>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
</div>
